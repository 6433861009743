import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import LibraryForm from '../components/LibraryForm';
import LibraryTabs from '../components/LibraryTabs';
import { CREATE_LIBRARY } from '../graphql/Mutations';

const BasicDetails = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [createLibrary] = useMutation(CREATE_LIBRARY, {
    onError: () => {},
  });

  const createLibraryFun = async (values) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await createLibrary({
      variables: {
        data: {
          city: values?.city,
          state: values?.state,
          contactNo: values?.contact,
          address: values?.address?.trim(),
          latLong: {
            latitude: parseFloat(values?.latitude),
            longitude: parseFloat(values?.longitude),
          },
          email: values?.email?.trim(),
          firstName: values?.firstName?.trim(),
          lastName: values?.lastName?.trim(),
          name: values?.name?.trim(),
          pincodes: values?.libraryPincode,
          preferredTimeFrom: values?.from?.$d,
          preferredTimeTo: values?.to?.$d,
          serviceableArea: parseFloat(values?.serviceableArea),
          type: values?.facilityType,
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`}
        />
        <div className="portal-header">Add New Library</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <div className="library-form">
        <LibraryTabs>
          <LibraryForm
            form={form}
            onFinish={createLibraryFun}
            handleShowPrompt={handleShowPrompt}
          />
        </LibraryTabs>
      </div>
    </>
  );
};

export default BasicDetails;
